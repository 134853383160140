<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 4L3.5 13"
      stroke="#868D9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.75 7C5.7165 7 6.5 6.2165 6.5 5.25C6.5 4.2835 5.7165 3.5 4.75 3.5C3.7835 3.5 3 4.2835 3 5.25C3 6.2165 3.7835 7 4.75 7Z"
      stroke="#868D9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.25 13.5C12.2165 13.5 13 12.7165 13 11.75C13 10.7835 12.2165 10 11.25 10C10.2835 10 9.5 10.7835 9.5 11.75C9.5 12.7165 10.2835 13.5 11.25 13.5Z"
      stroke="#868D9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
